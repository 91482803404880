/* Resets */

.intro-section{
    background-color:#BE0C0C;
    height: 66vh;
}
.process img {
    height: 159px;
    width: 241px;
    border-radius: 15px;
}

.process .card-footer{
    background: none!important;
    border-top: 0px solid rgba(0,0,0,.125);
}



/* Jumbotron (hero) */

/* Bustom button */
.btn-primary {
    background: #1096fc;
    border: 0;
}
.btn-primary:hover {
    background: #0384e5;
}



