



.contact .container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 88px;
    padding-bottom: 88px;
}



.submit-contact{
    width: 90%;
    color: white;
    height: 60px;
    border-radius: 10px;
    background: #29813A!important;
    box-shadow: 0px 5px 10px -2px rgba(0,0,0,0.75);
  
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0
}



::placeholder {
    color: black;
    opacity: 1
}



.form {
    width: 100%;
    max-width: 1014px;
    min-height: 651px;
    height: 100%;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-radius: 40px;
}

/* copied  */
.custom-container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 2rem;
    background-color: #fafafa;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center
}
.contact-info-form {
    position: relative;
    background: #f9f9f9;
}
.contact-info-form .title {
    color:black
}

.bg-absolute {
   
    position: absolute;
    width: 110px;
    height: 100px;
    background-image: url('../../assets/onehumber1.png');
    /* border: 22px solid #d50000; */
    border-radius: 50%;
    bottom: -77px;
    right: 50px;
    opacity: 0.3;
}


/* .circle {
    border-radius: 50%;
    background: linear-gradient(135deg, transparent 20%, #a7323254);
    position: absolute
}

.circle.one {
    width: 130px;
    height: 130px;
    top: 130px;
    right: -40px
}

.circle.two {
    width: 80px;
    height: 80px;
    top: 10px;
    right: 30px
}

.contact-info-form:before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: #D50000;
    transform: rotate(45deg);
    bottom: 66px;
    left: -13px
} */

/* form {
    padding: 2.3rem 2.2rem;
    z-index: 10;
    overflow: hidden;
    position: relative
} */

form {
    padding: 2.3rem 2.2rem; 
    z-index: 10;
    overflow: hidden;
    position: relative;
    padding-left: 60px;
    padding-top: 2.3rem;
    padding-bottom: 2.2rem;
}

.title {
    color: #fff;
    
    
   
    line-height: 1;
    font-weight: 700;
    font-size: 35px;
    font-family: 'Myriad Pro';
    
   
    margin-bottom: 70px!important;
    margin-top: 61px!important;
}

.social-input-containers {
    position: relative;
    margin: 1rem 0
}



.input {
    margin-bottom:5px;
    width: 90%;
    outline: none;
    border: 2px solid #fafafa;
    background: white;
    padding: 0.6rem 1.2rem;
    color: black;
    font-weight: 500;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 10px;
    transition: 0.3s;
    height: 60px;
    box-shadow: 0px 8px 5px -7px rgba(0,0,0,0.78);
}


.social-input-containers label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    padding: 0 0.4rem;
    color: #fafafa;
    font-size: 0.9rem;
    font-weight: 400;
    pointer-events: none;
    z-index: 1000;
    transition: 0.5s
}

.social-input-containers.textarea label {
    top: 1rem;
    transform: translateY(0)
}

.btn {
    padding: 0.6rem 1.3rem;
    
    font-size: 0.95rem;
   
    line-height: 1;
   
}

.btn:hover {
    background-color: transparent;
    color: #fff
}

.social-input-containers span {
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500
}

.social-input-containers span:before,
.social-input-containers span:after {
    content: "";
    position: absolute;
    width: 10%;
    opacity: 0;
    transition: 0.3s;
    height: 5px;
    background-color: #d50000;
    top: 50%;
    transform: translateY(-50%)
}

.social-input-containers span:before {
    left: 50%
}

.social-input-containers span:after {
    right: 50%
}

.social-input-containers.focus label {
    top: 0;
    transform: translateY(-50%);
    left: 25px;
    font-size: 0.8rem
}

.social-input-containers.focus span:before,
.social-input-containers.focus span:after {
    width: 50%;
    opacity: 1
}

.contact-info {
    padding: 2.3rem 2.2rem;
    position: relative
}

.contact-info .title {
    color: white;
    margin-left:30px
}
.contact-info p{
    font-size: 24px;
}



.text {
    color: #333;
    margin: 1.5rem 0 2rem 0
    
}


.social-information {
    display: flex;
    color: #555;
    margin: 0.7rem 0;
    align-items: center;
    font-size: 0.95rem
}

.icon {
    width: 28px;
    margin-right: 0.7rem
}







.social-icons a:hover {
    transform: scale(1.05)
}

/* .contact-info:before {
    content: "";
    position: absolute;
    width: 110px;
    height: 100px;
    border: 22px solid #d50000;
    border-radius: 50%;
    bottom: -77px;
    right: 50px;
    opacity: 0.3
} */
.contact-info {
    padding: 2.3rem 2.2rem;
    position: relative;
    background: #29813A!important;
}
.social-information i {
    font-size: 22px;
    margin-bottom: 23px;
    margin-right: 8px;
    color: #cf0606
}

.big-circle {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: linear-gradient(to bottom, #d50000, #cb0b0b);
    bottom: 50%;
    right: 50%;
    transform: translate(-40%, 38%)
}

.big-circle:after {
    content: "";
    position: absolute;
    width: 360px;
    height: 360px;
    background-color: #fafafa;
    border-radius: 50%;
    top: calc(50% - 180px);
    left: calc(50% - 180px)
}

.square {
    position: absolute;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(181%, 11%);
    opacity: 0.2
}

.info{
    margin-left: 52px;
    margin-top: 33px;
    color:white
}
.info p {
    color: white;
}


@media(min-width:850px){
    .contact-info .row{
        margin-left: 90px;

    }
    .info-social{
        color:white;
        font-size: 25px;
        font-weight: 700;
        line-height: 49px;
        font-family: 'Myriad Pro', sans-serif;
                                                
    }
}
@media(max-width:850px){
    .input{
        width: 100%;
    }
    .submit-contact{
        position: relative;
    left: 27%;
    right: 27%;
    width: 50%;
    }
    .contact .container{
        padding-left: 0!important;
    padding-right: 0!important;
    }
    .contact-info .row{
        display: flex;
        flex-wrap: wrap;
        margin-right: -10px;
        /* margin-left: -15px; */
        align-items: center;
        justify-content: center;
        text-align: center;
        align-content: center;
        margin-left: -10px;
    }
    .info-social:nth-child(3){
        text-align: center;
    width: 100%!important;
    }
    .info-social{
        position: relative;
    width: 50%!important;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: -23px;
    color: white;
    }
    
    .title{
        text-align: center;
    }
    .social-information:nth-child(1){
        font-size: 20px;
        width: auto;
        height: auto;
        padding: 5px 0px 5px 10px;
        float: left;
        margin-left: -96px;
    }
    .social-information:nth-child(2){
        width: auto;
    height: auto;
    padding: 7px 0px 9px 0px;
    float: right;
    font-size: 20px;
    margin-right: -22px;
    }
    .social-information:nth-child(3){
        clear:both;
        width:inherit;
        height:auto;
        position:static;
        padding: 0px 10px 5px 10px;
    }
    }

@media (max-width: 850px) {
    .form {
        grid-template-columns: 1fr
    }
    

    .contact-info:before {
        bottom: initial;
        top: -75px;
        right: 65px;
        transform: scale(0.95)
    }

    .contact-info-form:before {
        top: -13px;
        left: initial;
        right: 70px
    }

    .square {
        transform: translate(140%, 43%);
        height: 350px
    }

    .big-circle {
        bottom: 75%;
        transform: scale(0.9) translate(-40%, 30%);
        right: 50%
    }

    .text {
        margin: 1rem 0 1.5rem 0
    }

    .social-media {
        padding: 1.5rem 0 0 0
    }
}
@media(max-width:808px){
    .contact-info p{
        font-size:18px;
        line-height:2
    }

    
}
@media (max-width: 480px) {
    .container {
        padding: 1.5rem
    }

    .contact-info:before {
        display: none
    }
    .contact-info p {
        
            font-size: 19px;
        
    }
    .square,
    .big-circle {
        display: none
    }

    form,
    .contact-info {
        padding: 1.7rem 1.6rem
    }

    .text,
    .social-information,
    .social-media p {
        font-size: 0.8rem
    }

    .title {
        font-size: 1.15rem
    }

    .social-icons a {
        width: 30px;
        height: 30px;
        line-height: 30px
    }

    .icon {
        width: 23px
    }

    .input {
        padding: 0.45rem 1.2rem
    }

    .btn {
        padding: 0.45rem 1.2rem
    }
}

.address{
    margin-left: 20px;
    line-height: 1;
}