
  .service-div .card .card-body {
    /* margin: 0; */
    margin-bottom: 10px;
    height: 450px;;
  }
  
  .card-link {
    color: #fff;
    text-decoration: none;
    opacity: 1;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
            transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
  }
  .card-link:hover {
    opacity: 0.65;
  }
  
  
  


.card img{
    height: 60%;
    width: 100%;
    object-fit: cover;
}
.service-div .card{
    position:relative;
    z-index: -1;
/*     
    width: 500px;
    height: 250px;
    margin: 5rem auto;
    background-color: #fff;
    color:#fff;
    overflow: hidden; */
}

.service-div .card-title{
    margin-bottom: .75rem;
    font-family: 'BR Firma';
    /* font-size: 19px; */
    font-weight: 700;
}

.service-div .card-body{
    
    position: absolute;
    top: auto;
    bottom: 0;
    opacity: .6;
    left: 0;
    width: 100%;
    /* height: 124%; */
    background-color: black;
    padding: 15px;
    z-index: 999999988;
    visibility: hidden!important;
    
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    /* -webkit-transition: opacity 0.1s 0.3s, -webkit-transform 0.4s;
    transition: opacity 0.1s 0.3s, -webkit-transform 0.4s;
    transition: transform 0.4s, opacity 0.1s 0.3s ease-out 2s;
    transition: transform 0.4s, opacity 0.1s 0.3s, -webkit-transform 0.4s; */
    transition: ease-in-out 1s;
}
.service-div .card:hover .card-body{
   visibility: visible!important;
    opacity: .9;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    -webkit-transition: opacity 0.1s, -webkit-transform 0.4s;
    transition: ease-in-out 1s;
    /* transition: opacity 0.1s, -webkit-transform 0.4s;
    transition: transform 0.4s, opacity 0.1s;
    transition: transform 0.4s, opacity 0.1s, -webkit-transform 0.4s; */
  }


  .service-div .card .card-body p {
      position: relative;
      z-index: 99999999;
    background-color: #2BB445;
    opacity: 1;
    height: 40px;
    padding-top: 6px;
    padding-bottom: 7px;
    margin-top: 16px;
      
  }







.foreground{
    
    /* width: 300px!important; */
    position: absolute;
    top: 0;
    left: 5%;
    right: 5%;
    bottom: 0;
    /* transform: translateY(58%); */
    background-color:  #060101c2;
    transition: .5s ease-in-out;
    z-index: 9999;
    visibility: hidden;
    height: 0%;


}


.foreground-options:hover{
    transition: all 1s;
    /* transform: translateY(0); */
    visibility: visible;
    height: 100%;
}
/* .foreground-options{
        
        display: flex;
        justify-content: center;
        
        align-items: center;
        height: 200px;
}
.foreground-options div{
    display: block;
} */

/* .foreground-options div div{
    background: #2BB445;
    
    padding-left: 70px;
    padding-right: 70px;
    height: 2.7rem;
    margin-top: 1rem;
    padding-top: 0.4rem;
    
    transform: translateY(-100%);
    transition: ease-in-out 1s;
    

} */
.foreground-options div div{
    transform: translateY(0%)!important;
   
}

/* .service-div:hover .foreground{
    transition: all 1s;
   
    visibility: visible;
    height: 100%;
  } */
.service-div  .card{
    width: 100%!important;
    height: 450px;
    position: relative;
}
.box-service{
    display: flex;
    flex-direction: column;


}
.span{
    text-align: center;
    padding-top: 7px;
    padding-bottom: 20px;
}
.box-service{
    background: #060101;

}

.box-service div{
    color:white;
    background: #29813A5E;

    height: 2.6rem;
}

@media (max-width: 576px) { 
    .service-div{
        padding-bottom: 25%;
    }
    /* .service-div .card{
        width: 100%!important;
    } */
 }

 @media only screen and (min-width:578px) and (max-width:768px){
    
    .service-div{
        padding-bottom: 97px;
    }
}