.choose {
   height: 100%;
   width: 100%;
   padding: 3rem 0rem;
}

.choose > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 100%;

}

.choose > h2 {
    padding: 20px 0px;
    text-align: center;
}

.card {
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    /* padding: 10px; */
    margin: 10px 0px;
    border: none;


}

.card > h4 {
    text-align: left;
    color: #111111;
    transition: all .5s;
    cursor: pointer;
    width: 100%;
    /* margin: 5px 0px; */
    transition: all .4s;
}

.card > p {
    text-align: left;
    cursor: pointer;
    width: 100%;
    /* margin: 5px 0px; */
}

.card > h4:hover {
    color: #ee212b;

}



.choose_img {
    object-fit: cover;
    width: 100%;
    /* height: 100%!important; */
    border-radius: 10px;
    transform: scale(0.95);
    transition: all .4s;
}

.card:hover .choose_img {
    transform: scale(1)
}

.content_choose {
    width: fit-content;
     height: fit-content;
      height: 100px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    margin-top: 10px;
}

