.navbar {
  background: #ffffffff !important;
  padding: none;
  transition: 0.6s;
}

.navbar-light .navbar-nav .nav-link {
    color: rgb(0 0 0 / 84%)!important;
}
.bg-light {
  background-color: #ffffff !important;
}
.sticky {
  box-shadow: 0px 5px 12px 0px rgba(21, 20, 20, 0.6);
  position: sticky !important;

  height: 14% !important;
  padding: 5px 100px !important;
}
.sticky-md {
  margin: 0px 0px !important;
  padding: 30px 20px !important;
  box-shadow: 0px 5px 12px 0px rgba(21, 20, 20, 0.6);
}
@media (min-width: 567px) {
  .vertical {
    border-left: 2px solid #0000000f;
    height: 20px;
    position: absolute;
    /* padding-left: 88%; */
    left: 50%;
    top: 30%;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
    font-family: "BR Firma";
    font-weight: 400;
    font-size: 22px;
  }
}
@media (max-width: 787) {
  .sticky {
    padding: 5px 50px !important;
    
  }
}
@media (max-width: 565px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr ifr;
    grid-gap: 10px;
  }
  .humberlogo-nav {
    height: 50px;
    image-rendering: pixelated
    /* transform: rotate(90deg) scale(2.5); */
  }
}
.navbar .logo {
  width: 107%;
  image-rendering: pixelated
}

.ml-auto {
  position: relative;
}

.humberlogo-nav {
  /* height: 80px; */
 
  height: 50px;
    /* image-rendering: -webkit-optimize-contrast; */
    width: 167px;
    object-fit: contain;
}
