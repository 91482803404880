.jumbotron {
    position: relative;
    background:  no-repeat bottom center / cover;
}

.jumbotron:before {
    /* position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 72% )!important; */
}
.jumbotron .container {
    padding: 75px 0;
}
.jumbotron h1, .jumbotron .lead {
    color: #fff;
    font-family: 'BR Firma';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
}

.hero .jumbotron p{
   
    color: #ffffff;
    /* margin-top: 20px; */
    padding-top: 30px;
    font-family: 'BR Firma';
    font-size: 22px;
    font-weight: 300;
    font-style: normal;
}
.hero .jumbotron .col{
    padding-left: 30px;
    padding-right: 30px;
}

/* .hero .jumbotron .col-md-6 img{
    
    
    height: 90%;
    margin-left: 79px;
} */

@media (min-width:576px){
    .box_logo_md{
        display: none;

    }
}
@media (max-width:767px){
    .box_logo_md{
        background: black;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .jumbotron{
        padding:0
    }
    .box_logo_md{
        display:block;
        
    }
   .hero .jumbotron .d-flex .text-center-md{
    text-align: center!important;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 29px;
    margin-left: -1%;
    margin-right: 11%;
   }
   .hidden{
       display:none
   }
   .hero .jumbotron p {
    text-align: center!important;
    color: #ffffff;
    /* margin-top: 20px; */
    padding-top: 30px;
    padding: 5%;
    /* display: flex; */
    margin-right: 0%;
    /* font-family: BRFirmaBlack ; */


}
.box_logo_d{
    display: none;
}
.box_logo_md{
    width: 100%;
    padding: 0;
    margin: auto;
}
}

@media (max-width:432px){
    .hero .jumbotron .d-flex .text-center-md{
        padding-left: 42px;
        padding-right: 0px;
    }
}

@media (max-width: 576px)  { 
    .box_logo_md{
        background:black
    }
    .jumbotron{
        padding:0
    }
   .hero .jumbotron .d-flex .text-center-md{
    text-align: center!important;
    padding-left: 53px;
    padding-right: 0px;
    font-size: 29px;
    margin-left: -1%;
    margin-right: 11%;
   }
   .hidden{
       display:none
   }
   .hero .jumbotron p {
    text-align: center!important;
    color: #ffffff;
    /* margin-top: 20px; */
    padding-top: 30px;
    padding: 3%;
    /* display: flex; */
    margin-right: 0%;

}

.box_logo_d{
    display: none;
}
.box_logo_md{
    width: 100%;
    padding: 0;
    margin: auto;
   
}

 }

.jumbo-bg{
    transition:1s ease-in-out;
}
 .jumbo-bg:hover{
    
        transform: scale(1.07);
        transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
      
 }

 