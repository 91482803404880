.process {
  background: #f9fffa;
  height: auto;
  padding: 6%;
}

@media (min-width:576px) {
 .process img{
  height: 290px;
  width: 290px;
 } 

  
}

@media (max-width: 576px) {
  .col-grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
  }

  .col-grid-2 .col-md-3 {
    padding-right: 0px !important;
    padding-left: 0 !important;
  }
  .process-mobile {
    padding-left: 0;
    padding-right: 0;
  }

  .process img{
    height: 166px;
    width: 213px;
  }
}
