.tinted-bg {
  background: linear-gradient(
    88.57deg,
    #3f4d59 52.91%,
    rgba(37, 9, 9, 0) 90.87%
  );
}
.overlay-bg {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  left: 0;
  top: 0;
  background: rgba(244, 244, 244, 0.79);
  z-index: 11111;
}
.section-abound {
  position: relative;
  top: 100px;
}

.no-boundaries {
  color: white;
}
.section-abound-p {
  color: white;

    font-family: 'BR Firma';
      font-size: 22px;
     
      font-style: normal;
      font-weight: 300;
  
}

.section-abound .btn {
  border: 1px solid  #FFFFFF;
  background: none;
  color: #BEFFCB
  !important;
}
.section-abound .btn:hover {
  background: none;
}
.section-abound .btn:focus {
  background: none !important;
}
.section-abound .col-md-6 {
  max-width: 43%;
}
.get_intouch {
  position: relative;
  background: no-repeat bottom center / cover;
}
.get_intouch:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    88.57deg,
    #3f4d59 52.91%,
    rgba(37, 9, 9, 0) 90.87%
  );
}
.get_intouch .row::after{
    padding-top: 0px;
}
.left-align{
    
    flex: 0 0 43%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

}

@media (max-width: 757px) {
  .section-abound-p {
    color: white;
    font-family: 'BR Firma';
      font-size: 22px;
      text-align: center!important;
      font-style: normal;
      font-weight: 400;
  }
  .get_intouch{
    height: 718px!important;
    text-align: center;
  }
  .get_intouch:before {
    background: linear-gradient( 
        177.57deg
        , #3f4d59 30.91%, rgba(37, 9, 9, 0) 113.87% );;
  }
  .second-col{
      display: none;
  }
  .left-align{
    flex: 1 1 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .left-align .mb-3{
    margin-top: 20px;
  }
  .left-align .h1 {
    text-align: center;
  }
  .section-abound {
    position: relative;
    top: 44px;
  }
}
