@import url(http://fonts.cdnfonts.com/css/myriad-pro);
@import url(http://fonts.cdnfonts.com/css/br-firma);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
@media (max-width:787){
  body,html{
    overflow-x: hidden;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* @font-face {
  font-family: 'BR Firma';
  src: local('Br Firma'), url('./assets/fonts/BRFirmaBlack.otf') format('opentype');
}
@font-face {
  font-family: 'BR Firma ';
  src: local('Br Firma') url('./assets/fonts/BRFirmaBold.otf') format('opentype');
}


@font-face {
  font-family: 'BR Firma';
  src: local('Br Firma') url('./assets/fonts/BRFirmaMedium.otf') format('opentype')
}


@font-face {
  font-family: 'BR Firma ';
  src: local('Br Firma') url('./assets/fonts/BRFirmaExtraLight.otf') format('opentype');
  font-weight: lighter;
  
}
@font-face {
  font-family: 'BR Firma ';
  src: local('Br Firma2') url('./assets/fonts/BRFirmaRegular.otf') format('opentype')
}

@font-face {
  font-family: 'BR Firma ';
  src: local('Br Firma') url('./assets/fonts/BRFirmaThin.otf') format('opentype')
}

@font-face {
  font-family: 'BR Firma ';
  src: local('Br Firma') url('./assets/fonts/BRFirmaLight.otf') format('opentype')
}

@font-face {
  font-family: 'BR Firma lighter';
  src: local('Br Firma') url('./assets/fonts/BRFirmaExtraLight.otf') format('opentype');
  font-weight:100;
  
} */



.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
body::-webkit-scrollbar {
  width: 1em;
}
 
body::-webkit-scrollbar-track {
  background:#3f4d5952;
}
 
body::-webkit-scrollbar-thumb {
  background-color: #3f4d59;
  outline: 1px solid slategrey;
}






.navbar {
  background: #ffffffff !important;
  padding: none;
  transition: 0.6s;
}

.navbar-light .navbar-nav .nav-link {
    color: rgb(0 0 0 / 84%)!important;
}
.bg-light {
  background-color: #ffffff !important;
}
.sticky {
  box-shadow: 0px 5px 12px 0px rgba(21, 20, 20, 0.6);
  position: -webkit-sticky !important;
  position: sticky !important;

  height: 14% !important;
  padding: 5px 100px !important;
}
.sticky-md {
  margin: 0px 0px !important;
  padding: 30px 20px !important;
  box-shadow: 0px 5px 12px 0px rgba(21, 20, 20, 0.6);
}
@media (min-width: 567px) {
  .vertical {
    border-left: 2px solid #0000000f;
    height: 20px;
    position: absolute;
    /* padding-left: 88%; */
    left: 50%;
    top: 30%;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
    font-family: "BR Firma";
    font-weight: 400;
    font-size: 22px;
  }
}
@media (max-width: 787) {
  .sticky {
    padding: 5px 50px !important;
    
  }
}
@media (max-width: 565px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr ifr;
    grid-gap: 10px;
  }
  .humberlogo-nav {
    height: 50px;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated
    /* transform: rotate(90deg) scale(2.5); */
  }
}
.navbar .logo {
  width: 107%;
  -ms-interpolation-mode: nearest-neighbor;
      image-rendering: -moz-crisp-edges;
      image-rendering: pixelated
}

.ml-auto {
  position: relative;
}

.humberlogo-nav {
  /* height: 80px; */
 
  height: 50px;
    /* image-rendering: -webkit-optimize-contrast; */
    width: 167px;
    object-fit: contain;
}



.footer {
    padding-top: 74px;
    padding-bottom: 54px;
    background: #212121;
    background: #02060D!important;
}
.footer li:nth-of-type(n+2) {
    margin-left: 8px;
}
.footer a {
    font-size: 18px;
    color: rgba(255, 255, 255, .5);
    transition: color 0.235s ease-in-out;
    font-family: 'Br Firma', sans-serif;
}
.footer a:focus, .footer a:hover {
    color: rgba(255, 255, 255, .25);
}

.footer .container .row p {
    color: white;
    font-weight: 400;
    font-size: 17px;
    font-family: 'Br Firma';
}
.footer .container .row h5, h6 {
    color: #BEFFCB;

}
.footer .social_wrapper{
    
}
.footer .container .row h3{
    color: #BEFFCB;
    margin-bottom: 27px;
    font-family: 'BR Firma', sans-serif;
                                                
}
.footer .fab{
    color: #BEFFCB;
    font-size: 30px;
}
.humber_email{
    color:white!important;
    font-size:26px;
}
hr{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 2px solid #ffffff;
}

.box{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 119px;
}


@media(max-width:767px){
    .footer-md-hidden{
        display: none;
    }
    .md-margin{
        
        margin-bottom: 2px!important;
        margin-top: 16px;
    }
    .footer .social_wrapper {
        width: 10px;
    }


}

@media(max-width:480px){
     .logo-md svg{
      
    height: 30px;
    margin-left: -54px;
    margin-bottom: -40px;
     }
     .humber_email{
        font-size: 11px!important;
     }

}
@media(max-width:351px){
    .footer-top-row {
        display: grid!important;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
    width: auto;
    }
}





  .service-div .card .card-body {
    /* margin: 0; */
    margin-bottom: 10px;
    height: 450px;;
  }
  
  .card-link {
    color: #fff;
    text-decoration: none;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
  }
  .card-link:hover {
    opacity: 0.65;
  }
  
  
  


.card img{
    height: 60%;
    width: 100%;
    object-fit: cover;
}
.service-div .card{
    position:relative;
    z-index: -1;
/*     
    width: 500px;
    height: 250px;
    margin: 5rem auto;
    background-color: #fff;
    color:#fff;
    overflow: hidden; */
}

.service-div .card-title{
    margin-bottom: .75rem;
    font-family: 'BR Firma';
    /* font-size: 19px; */
    font-weight: 700;
}

.service-div .card-body{
    
    position: absolute;
    top: auto;
    bottom: 0;
    opacity: .6;
    left: 0;
    width: 100%;
    /* height: 124%; */
    background-color: black;
    padding: 15px;
    z-index: 999999988;
    visibility: hidden!important;
    
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    /* -webkit-transition: opacity 0.1s 0.3s, -webkit-transform 0.4s;
    transition: opacity 0.1s 0.3s, -webkit-transform 0.4s;
    transition: transform 0.4s, opacity 0.1s 0.3s ease-out 2s;
    transition: transform 0.4s, opacity 0.1s 0.3s, -webkit-transform 0.4s; */
    transition: ease-in-out 1s;
}
.service-div .card:hover .card-body{
   visibility: visible!important;
    opacity: .9;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    transition: ease-in-out 1s;
    /* transition: opacity 0.1s, -webkit-transform 0.4s;
    transition: transform 0.4s, opacity 0.1s;
    transition: transform 0.4s, opacity 0.1s, -webkit-transform 0.4s; */
  }


  .service-div .card .card-body p {
      position: relative;
      z-index: 99999999;
    background-color: #2BB445;
    opacity: 1;
    height: 40px;
    padding-top: 6px;
    padding-bottom: 7px;
    margin-top: 16px;
      
  }







.foreground{
    
    /* width: 300px!important; */
    position: absolute;
    top: 0;
    left: 5%;
    right: 5%;
    bottom: 0;
    /* transform: translateY(58%); */
    background-color:  #060101c2;
    transition: .5s ease-in-out;
    z-index: 9999;
    visibility: hidden;
    height: 0%;


}


.foreground-options:hover{
    transition: all 1s;
    /* transform: translateY(0); */
    visibility: visible;
    height: 100%;
}
/* .foreground-options{
        
        display: flex;
        justify-content: center;
        
        align-items: center;
        height: 200px;
}
.foreground-options div{
    display: block;
} */

/* .foreground-options div div{
    background: #2BB445;
    
    padding-left: 70px;
    padding-right: 70px;
    height: 2.7rem;
    margin-top: 1rem;
    padding-top: 0.4rem;
    
    transform: translateY(-100%);
    transition: ease-in-out 1s;
    

} */
.foreground-options div div{
    -webkit-transform: translateY(0%)!important;
            transform: translateY(0%)!important;
   
}

/* .service-div:hover .foreground{
    transition: all 1s;
   
    visibility: visible;
    height: 100%;
  } */
.service-div  .card{
    width: 100%!important;
    height: 450px;
    position: relative;
}
.box-service{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;


}
.span{
    text-align: center;
    padding-top: 7px;
    padding-bottom: 20px;
}
.box-service{
    background: #060101;

}

.box-service div{
    color:white;
    background: #29813A5E;

    height: 2.6rem;
}

@media (max-width: 576px) { 
    .service-div{
        padding-bottom: 25%;
    }
    /* .service-div .card{
        width: 100%!important;
    } */
 }

 @media only screen and (min-width:578px) and (max-width:768px){
    
    .service-div{
        padding-bottom: 97px;
    }
}

.sec-services{
    padding-bottom: 5%!important;
    padding-top:5%!important;
    background: #F0F0F0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    
}

.modal {
    background: black;
}

.services_card {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

@media (max-width: 800px) {
    .services_card {
        -webkit-justify-content: center;
                justify-content: center;
    }
}

@media only screen and (min-width:578px) and (max-width:768px){
    .sec-services{
        padding-bottom: 16%!important;
    }
    .service-div{
        padding-bottom: 97px;
    }
}


/* Resets */

.intro-section{
    background-color:#BE0C0C;
    height: 66vh;
}
.process img {
    height: 159px;
    width: 241px;
    border-radius: 15px;
}

.process .card-footer{
    background: none!important;
    border-top: 0px solid rgba(0,0,0,.125);
}



/* Jumbotron (hero) */

/* Bustom button */
.btn-primary {
    background: #1096fc;
    border: 0;
}
.btn-primary:hover {
    background: #0384e5;
}




.inovation-1{
    color:white;
    margin-top:60px;
    
}
p.inovation-1{
    font-family: 'Br Firma';
}
.h3{
    width: 69%;
    font-size: 40px;
    font-family: 'Br Firma';
}

.blockquote{
    width: 444px;
    word-spacing: 0px;
    font-size: 41px;
    text-align: left;

}

.intro-section{
    height: auto;
}
.intro-section .container-fluid .row{
    padding-top: 5%;
    padding-bottom: 5%;
}
    




.contact .container{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 88px;
    padding-bottom: 88px;
}



.submit-contact{
    width: 90%;
    color: white;
    height: 60px;
    border-radius: 10px;
    background: #29813A!important;
    box-shadow: 0px 5px 10px -2px rgba(0,0,0,0.75);
  
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0
}



::-webkit-input-placeholder {
    color: black;
    opacity: 1
}



:-ms-input-placeholder {
    color: black;
    opacity: 1
}



::placeholder {
    color: black;
    opacity: 1
}



.form {
    width: 100%;
    max-width: 1014px;
    min-height: 651px;
    height: 100%;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-radius: 40px;
}

/* copied  */
.custom-container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 2rem;
    background-color: #fafafa;
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center
}
.contact-info-form {
    position: relative;
    background: #f9f9f9;
}
.contact-info-form .title {
    color:black
}

.bg-absolute {
   
    position: absolute;
    width: 110px;
    height: 100px;
    background-image: url(/static/media/onehumber1.fe9c4ea3.png);
    /* border: 22px solid #d50000; */
    border-radius: 50%;
    bottom: -77px;
    right: 50px;
    opacity: 0.3;
}


/* .circle {
    border-radius: 50%;
    background: linear-gradient(135deg, transparent 20%, #a7323254);
    position: absolute
}

.circle.one {
    width: 130px;
    height: 130px;
    top: 130px;
    right: -40px
}

.circle.two {
    width: 80px;
    height: 80px;
    top: 10px;
    right: 30px
}

.contact-info-form:before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: #D50000;
    transform: rotate(45deg);
    bottom: 66px;
    left: -13px
} */

/* form {
    padding: 2.3rem 2.2rem;
    z-index: 10;
    overflow: hidden;
    position: relative
} */

form {
    padding: 2.3rem 2.2rem; 
    z-index: 10;
    overflow: hidden;
    position: relative;
    padding-left: 60px;
    padding-top: 2.3rem;
    padding-bottom: 2.2rem;
}

.title {
    color: #fff;
    
    
   
    line-height: 1;
    font-weight: 700;
    font-size: 35px;
    font-family: 'Myriad Pro';
    
   
    margin-bottom: 70px!important;
    margin-top: 61px!important;
}

.social-input-containers {
    position: relative;
    margin: 1rem 0
}



.input {
    margin-bottom:5px;
    width: 90%;
    outline: none;
    border: 2px solid #fafafa;
    background: white;
    padding: 0.6rem 1.2rem;
    color: black;
    font-weight: 500;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 10px;
    transition: 0.3s;
    height: 60px;
    box-shadow: 0px 8px 5px -7px rgba(0,0,0,0.78);
}


.social-input-containers label {
    position: absolute;
    top: 50%;
    left: 15px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    padding: 0 0.4rem;
    color: #fafafa;
    font-size: 0.9rem;
    font-weight: 400;
    pointer-events: none;
    z-index: 1000;
    transition: 0.5s
}

.social-input-containers.textarea label {
    top: 1rem;
    -webkit-transform: translateY(0);
            transform: translateY(0)
}

.btn {
    padding: 0.6rem 1.3rem;
    
    font-size: 0.95rem;
   
    line-height: 1;
   
}

.btn:hover {
    background-color: transparent;
    color: #fff
}

.social-input-containers span {
    position: absolute;
    top: 0;
    left: 25px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500
}

.social-input-containers span:before,
.social-input-containers span:after {
    content: "";
    position: absolute;
    width: 10%;
    opacity: 0;
    transition: 0.3s;
    height: 5px;
    background-color: #d50000;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%)
}

.social-input-containers span:before {
    left: 50%
}

.social-input-containers span:after {
    right: 50%
}

.social-input-containers.focus label {
    top: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 25px;
    font-size: 0.8rem
}

.social-input-containers.focus span:before,
.social-input-containers.focus span:after {
    width: 50%;
    opacity: 1
}

.contact-info {
    padding: 2.3rem 2.2rem;
    position: relative
}

.contact-info .title {
    color: white;
    margin-left:30px
}
.contact-info p{
    font-size: 24px;
}



.text {
    color: #333;
    margin: 1.5rem 0 2rem 0
    
}


.social-information {
    display: -webkit-flex;
    display: flex;
    color: #555;
    margin: 0.7rem 0;
    -webkit-align-items: center;
            align-items: center;
    font-size: 0.95rem
}

.icon {
    width: 28px;
    margin-right: 0.7rem
}







.social-icons a:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05)
}

/* .contact-info:before {
    content: "";
    position: absolute;
    width: 110px;
    height: 100px;
    border: 22px solid #d50000;
    border-radius: 50%;
    bottom: -77px;
    right: 50px;
    opacity: 0.3
} */
.contact-info {
    padding: 2.3rem 2.2rem;
    position: relative;
    background: #29813A!important;
}
.social-information i {
    font-size: 22px;
    margin-bottom: 23px;
    margin-right: 8px;
    color: #cf0606
}

.big-circle {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: linear-gradient(to bottom, #d50000, #cb0b0b);
    bottom: 50%;
    right: 50%;
    -webkit-transform: translate(-40%, 38%);
            transform: translate(-40%, 38%)
}

.big-circle:after {
    content: "";
    position: absolute;
    width: 360px;
    height: 360px;
    background-color: #fafafa;
    border-radius: 50%;
    top: calc(50% - 180px);
    left: calc(50% - 180px)
}

.square {
    position: absolute;
    height: 400px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(181%, 11%);
            transform: translate(181%, 11%);
    opacity: 0.2
}

.info{
    margin-left: 52px;
    margin-top: 33px;
    color:white
}
.info p {
    color: white;
}


@media(min-width:850px){
    .contact-info .row{
        margin-left: 90px;

    }
    .info-social{
        color:white;
        font-size: 25px;
        font-weight: 700;
        line-height: 49px;
        font-family: 'Myriad Pro', sans-serif;
                                                
    }
}
@media(max-width:850px){
    .input{
        width: 100%;
    }
    .submit-contact{
        position: relative;
    left: 27%;
    right: 27%;
    width: 50%;
    }
    .contact .container{
        padding-left: 0!important;
    padding-right: 0!important;
    }
    .contact-info .row{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        margin-right: -10px;
        /* margin-left: -15px; */
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        text-align: center;
        -webkit-align-content: center;
                align-content: center;
        margin-left: -10px;
    }
    .info-social:nth-child(3){
        text-align: center;
    width: 100%!important;
    }
    .info-social{
        position: relative;
    width: 50%!important;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: -23px;
    color: white;
    }
    
    .title{
        text-align: center;
    }
    .social-information:nth-child(1){
        font-size: 20px;
        width: auto;
        height: auto;
        padding: 5px 0px 5px 10px;
        float: left;
        margin-left: -96px;
    }
    .social-information:nth-child(2){
        width: auto;
    height: auto;
    padding: 7px 0px 9px 0px;
    float: right;
    font-size: 20px;
    margin-right: -22px;
    }
    .social-information:nth-child(3){
        clear:both;
        width:inherit;
        height:auto;
        position:static;
        padding: 0px 10px 5px 10px;
    }
    }

@media (max-width: 850px) {
    .form {
        grid-template-columns: 1fr
    }
    

    .contact-info:before {
        bottom: auto;
        bottom: initial;
        top: -75px;
        right: 65px;
        -webkit-transform: scale(0.95);
                transform: scale(0.95)
    }

    .contact-info-form:before {
        top: -13px;
        left: auto;
        left: initial;
        right: 70px
    }

    .square {
        -webkit-transform: translate(140%, 43%);
                transform: translate(140%, 43%);
        height: 350px
    }

    .big-circle {
        bottom: 75%;
        -webkit-transform: scale(0.9) translate(-40%, 30%);
                transform: scale(0.9) translate(-40%, 30%);
        right: 50%
    }

    .text {
        margin: 1rem 0 1.5rem 0
    }

    .social-media {
        padding: 1.5rem 0 0 0
    }
}
@media(max-width:808px){
    .contact-info p{
        font-size:18px;
        line-height:2
    }

    
}
@media (max-width: 480px) {
    .container {
        padding: 1.5rem
    }

    .contact-info:before {
        display: none
    }
    .contact-info p {
        
            font-size: 19px;
        
    }
    .square,
    .big-circle {
        display: none
    }

    form,
    .contact-info {
        padding: 1.7rem 1.6rem
    }

    .text,
    .social-information,
    .social-media p {
        font-size: 0.8rem
    }

    .title {
        font-size: 1.15rem
    }

    .social-icons a {
        width: 30px;
        height: 30px;
        line-height: 30px
    }

    .icon {
        width: 23px
    }

    .input {
        padding: 0.45rem 1.2rem
    }

    .btn {
        padding: 0.45rem 1.2rem
    }
}

.address{
    margin-left: 20px;
    line-height: 1;
}
.tinted-bg {
  background: linear-gradient(
    88.57deg,
    #3f4d59 52.91%,
    rgba(37, 9, 9, 0) 90.87%
  );
}
.overlay-bg {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  left: 0;
  top: 0;
  background: rgba(244, 244, 244, 0.79);
  z-index: 11111;
}
.section-abound {
  position: relative;
  top: 100px;
}

.no-boundaries {
  color: white;
}
.section-abound-p {
  color: white;

    font-family: 'BR Firma';
      font-size: 22px;
     
      font-style: normal;
      font-weight: 300;
  
}

.section-abound .btn {
  border: 1px solid  #FFFFFF;
  background: none;
  color: #BEFFCB
  !important;
}
.section-abound .btn:hover {
  background: none;
}
.section-abound .btn:focus {
  background: none !important;
}
.section-abound .col-md-6 {
  max-width: 43%;
}
.get_intouch {
  position: relative;
  background: no-repeat bottom center / cover;
}
.get_intouch:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    88.57deg,
    #3f4d59 52.91%,
    rgba(37, 9, 9, 0) 90.87%
  );
}
.get_intouch .row::after{
    padding-top: 0px;
}
.left-align{
    
    -webkit-flex: 0 0 43%;
    
            flex: 0 0 43%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

}

@media (max-width: 757px) {
  .section-abound-p {
    color: white;
    font-family: 'BR Firma';
      font-size: 22px;
      text-align: center!important;
      font-style: normal;
      font-weight: 400;
  }
  .get_intouch{
    height: 718px!important;
    text-align: center;
  }
  .get_intouch:before {
    background: linear-gradient( 
        177.57deg
        , #3f4d59 30.91%, rgba(37, 9, 9, 0) 113.87% );;
  }
  .second-col{
      display: none;
  }
  .left-align{
    -webkit-flex: 1 1 100%;
            flex: 1 1 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .left-align .mb-3{
    margin-top: 20px;
  }
  .left-align .h1 {
    text-align: center;
  }
  .section-abound {
    position: relative;
    top: 44px;
  }
}

.h-line{
    border: 1px solid #29813A;
    width: 16%;
    margin-left: auto;
    margin-right: auto;
    
}
.h-line-margin{
    margin-left: 0 !important;
}
.h1-element{
    font-family: 'BR Firma'; 
font-style: normal;
font-weight: 700;
margin-bottom: -25px;
}margin-bottom: -25px;
.display-none{
    display: none;
}

@media (max-width:757px){
    .h1-element{
        text-align: center!important;
    }
}


.slider-wrapper{
    padding-top: 90px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
}

.slick-slide img{
    height:200px;
    width:200px
}


@media(max-width:690px){
    .slick-slide img{
        height:100px;
        width:100px
    }
    .slick-dots li{
        margin: 0!important;
    }
}
.process {
  background: #f9fffa;
  height: auto;
  padding: 6%;
}

@media (min-width:576px) {
 .process img{
  height: 290px;
  width: 290px;
 } 

  
}

@media (max-width: 576px) {
  .col-grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
  }

  .col-grid-2 .col-md-3 {
    padding-right: 0px !important;
    padding-left: 0 !important;
  }
  .process-mobile {
    padding-left: 0;
    padding-right: 0;
  }

  .process img{
    height: 166px;
    width: 213px;
  }
}

.jumbotron {
    position: relative;
    background:  no-repeat bottom center / cover;
}

.jumbotron:before {
    /* position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 72% )!important; */
}
.jumbotron .container {
    padding: 75px 0;
}
.jumbotron h1, .jumbotron .lead {
    color: #fff;
    font-family: 'BR Firma';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
}

.hero .jumbotron p{
   
    color: #ffffff;
    /* margin-top: 20px; */
    padding-top: 30px;
    font-family: 'BR Firma';
    font-size: 22px;
    font-weight: 300;
    font-style: normal;
}
.hero .jumbotron .col{
    padding-left: 30px;
    padding-right: 30px;
}

/* .hero .jumbotron .col-md-6 img{
    
    
    height: 90%;
    margin-left: 79px;
} */

@media (min-width:576px){
    .box_logo_md{
        display: none;

    }
}
@media (max-width:767px){
    .box_logo_md{
        background: black;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .jumbotron{
        padding:0
    }
    .box_logo_md{
        display:block;
        
    }
   .hero .jumbotron .d-flex .text-center-md{
    text-align: center!important;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 29px;
    margin-left: -1%;
    margin-right: 11%;
   }
   .hidden{
       display:none
   }
   .hero .jumbotron p {
    text-align: center!important;
    color: #ffffff;
    /* margin-top: 20px; */
    padding-top: 30px;
    padding: 5%;
    /* display: flex; */
    margin-right: 0%;
    /* font-family: BRFirmaBlack ; */


}
.box_logo_d{
    display: none;
}
.box_logo_md{
    width: 100%;
    padding: 0;
    margin: auto;
}
}

@media (max-width:432px){
    .hero .jumbotron .d-flex .text-center-md{
        padding-left: 42px;
        padding-right: 0px;
    }
}

@media (max-width: 576px)  { 
    .box_logo_md{
        background:black
    }
    .jumbotron{
        padding:0
    }
   .hero .jumbotron .d-flex .text-center-md{
    text-align: center!important;
    padding-left: 53px;
    padding-right: 0px;
    font-size: 29px;
    margin-left: -1%;
    margin-right: 11%;
   }
   .hidden{
       display:none
   }
   .hero .jumbotron p {
    text-align: center!important;
    color: #ffffff;
    /* margin-top: 20px; */
    padding-top: 30px;
    padding: 3%;
    /* display: flex; */
    margin-right: 0%;

}

.box_logo_d{
    display: none;
}
.box_logo_md{
    width: 100%;
    padding: 0;
    margin: auto;
   
}

 }

.jumbo-bg{
    transition:1s ease-in-out;
}
 .jumbo-bg:hover{
    
        -webkit-transform: scale(1.07);
    
                transform: scale(1.07);
        transition: -webkit-transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
        transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
        transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95), -webkit-transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
      
 }

 
.humberLogo{
    top: 128px;
    left: 129px;
    position: absolute;
    height: 46%; 
    -ms-interpolation-mode: nearest-neighbor; 
        image-rendering: -moz-crisp-edges; 
        image-rendering: pixelated;

   
}

.demo-wrapper{
    position: relative;
    left:65px
}


@media only screen and (min-width: 576px) and (max-width: 767px) {
   

    .demo-wrapper{
        display: -webkit-flex;
        display: flex;
    /* justify-self: center; */
    -webkit-justify-content: center;
            justify-content: center;
    left:0
    }

    .humberLogo {
        top: 128px;
        position: absolute;
        height: 46%;
        left: auto;
    }
}
@media (max-width: 576px)  {
   

    .demo-wrapper{
        display: -webkit-flex;
        display: flex;
    /* justify-self: center; */
    -webkit-justify-content: center;
            justify-content: center;
    left:0
    }

    .humberLogo {
        top: 128px;
        position: absolute;
        height: 46%;
        left: auto;
    }

    
}


#emoji1, #emoji2, #emoji3 {
  opacity: 0
}

#emoji1, #emoji2, #emoji3, #emoji4, #emoji5, #emoji6 {
  font-size: 50px;
  position: absolute;
  top: -25px;
  left: -25px;
  
}

/* media query to adjust the circles bit to right */
/* @media only screen and (min-width: 538px) and (max-width: 577px) {
    #emoji1, #emoji2, #emoji3, #emoji4, #emoji5, #emoji6{
        left:12px
    }
}
@media only screen and (min-width: 589px) and (max-width: 600px) {
    #emoji1, #emoji2, #emoji3, #emoji4, #emoji5, #emoji6{
        left:25px
    }
}
@media only screen and (min-width: 600px) and (max-width: 612px) {
    #emoji1, #emoji2, #emoji3, #emoji4, #emoji5, #emoji6{
        left:30px
    }
}
@media only screen and (min-width: 627px) and (max-width: 650px) {
    #emoji1, #emoji2, #emoji3, #emoji4, #emoji5, #emoji6{
        left:54px
    }
}
@media only screen and (min-width: 675px) and (max-width: 700px) {
    #emoji1, #emoji2, #emoji3, #emoji4, #emoji5, #emoji6{
        left:61px
    }
} */
/* form mobile component */
.demo-md{
        position:relative;

}
#icon1, #icon2, #icon3{
    font-size: 50px;
    position: absolute;
    top: -25px;
    left: -25px;
    
    -webkit-justify-content: center;
    
            justify-content: center;
}
.humberLogo-md{
    position: absolute;
    height: 50%;
    top: 100px;
    left: 100px;

}

@media only screen and (min-width:602px) and (max-width:767px){
    .demo-md{
        position:relative;
        
        left: 20%;

}
}

@media (max-width:567px){
    .demo-md{
        left:15px
    }
}

.choose {
   height: 100%;
   width: 100%;
   padding: 3rem 0rem;
}

.choose > div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    height: 100%;
    width: 100%;

}

.choose > h2 {
    padding: 20px 0px;
    text-align: center;
}

.card {
    width: 400px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    /* align-items: center; */
    /* padding: 10px; */
    margin: 10px 0px;
    border: none;


}

.card > h4 {
    text-align: left;
    color: #111111;
    transition: all .5s;
    cursor: pointer;
    width: 100%;
    /* margin: 5px 0px; */
    transition: all .4s;
}

.card > p {
    text-align: left;
    cursor: pointer;
    width: 100%;
    /* margin: 5px 0px; */
}

.card > h4:hover {
    color: #ee212b;

}



.choose_img {
    object-fit: cover;
    width: 100%;
    /* height: 100%!important; */
    border-radius: 10px;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    transition: all .4s;
}

.card:hover .choose_img {
    -webkit-transform: scale(1);
            transform: scale(1)
}

.content_choose {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
     height: -webkit-fit-content;
     height: -moz-fit-content;
     height: fit-content;
      height: 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    margin-left: 10px;
    margin-top: 10px;
}


.sk-cube-grid {
  width: 100px;
    height: 100px;
    margin: 200px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1); 
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  } 
}


