.inovation-1{
    color:white;
    margin-top:60px;
    
}
p.inovation-1{
    font-family: 'Br Firma';
}
.h3{
    width: 69%;
    font-size: 40px;
    font-family: 'Br Firma';
}

.blockquote{
    width: 444px;
    word-spacing: 0px;
    font-size: 41px;
    text-align: left;

}

.intro-section{
    height: auto;
}
.intro-section .container-fluid .row{
    padding-top: 5%;
    padding-bottom: 5%;
}
    