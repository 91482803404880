
.sec-services{
    padding-bottom: 5%!important;
    padding-top:5%!important;
    background: #F0F0F0;
    height: fit-content;

    
}

.modal {
    background: black;
}

.services_card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media (max-width: 800px) {
    .services_card {
        justify-content: center;
    }
}

@media only screen and (min-width:578px) and (max-width:768px){
    .sec-services{
        padding-bottom: 16%!important;
    }
    .service-div{
        padding-bottom: 97px;
    }
}

