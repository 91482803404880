.h-line{
    border: 1px solid #29813A;
    width: 16%;
    margin-left: auto;
    margin-right: auto;
    
}
.h-line-margin{
    margin-left: 0 !important;
}
.h1-element{
    font-family: 'BR Firma'; 
font-style: normal;
font-weight: 700;
margin-bottom: -25px;
}margin-bottom: -25px;
.display-none{
    display: none;
}

@media (max-width:757px){
    .h1-element{
        text-align: center!important;
    }
}

