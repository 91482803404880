.slider-wrapper{
    padding-top: 90px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
}

.slick-slide img{
    height:200px;
    width:200px
}


@media(max-width:690px){
    .slick-slide img{
        height:100px;
        width:100px
    }
    .slick-dots li{
        margin: 0!important;
    }
}