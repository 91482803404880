.humberLogo{
    top: 128px;
    left: 129px;
    position: absolute;
    height: 46%; 
    image-rendering: pixelated;

   
}

.demo-wrapper{
    position: relative;
    left:65px
}


@media only screen and (min-width: 576px) and (max-width: 767px) {
   

    .demo-wrapper{
        display: flex;
    /* justify-self: center; */
    justify-content: center;
    left:0
    }

    .humberLogo {
        top: 128px;
        position: absolute;
        height: 46%;
        left: auto;
    }
}
@media (max-width: 576px)  {
   

    .demo-wrapper{
        display: flex;
    /* justify-self: center; */
    justify-content: center;
    left:0
    }

    .humberLogo {
        top: 128px;
        position: absolute;
        height: 46%;
        left: auto;
    }

    
}


#emoji1, #emoji2, #emoji3 {
  opacity: 0
}

#emoji1, #emoji2, #emoji3, #emoji4, #emoji5, #emoji6 {
  font-size: 50px;
  position: absolute;
  top: -25px;
  left: -25px;
  
}

/* media query to adjust the circles bit to right */
/* @media only screen and (min-width: 538px) and (max-width: 577px) {
    #emoji1, #emoji2, #emoji3, #emoji4, #emoji5, #emoji6{
        left:12px
    }
}
@media only screen and (min-width: 589px) and (max-width: 600px) {
    #emoji1, #emoji2, #emoji3, #emoji4, #emoji5, #emoji6{
        left:25px
    }
}
@media only screen and (min-width: 600px) and (max-width: 612px) {
    #emoji1, #emoji2, #emoji3, #emoji4, #emoji5, #emoji6{
        left:30px
    }
}
@media only screen and (min-width: 627px) and (max-width: 650px) {
    #emoji1, #emoji2, #emoji3, #emoji4, #emoji5, #emoji6{
        left:54px
    }
}
@media only screen and (min-width: 675px) and (max-width: 700px) {
    #emoji1, #emoji2, #emoji3, #emoji4, #emoji5, #emoji6{
        left:61px
    }
} */
/* form mobile component */
.demo-md{
        position:relative;

}
#icon1, #icon2, #icon3{
    font-size: 50px;
    position: absolute;
    top: -25px;
    left: -25px;
    
    justify-content: center;
}
.humberLogo-md{
    position: absolute;
    height: 50%;
    top: 100px;
    left: 100px;

}

@media only screen and (min-width:602px) and (max-width:767px){
    .demo-md{
        position:relative;
        
        left: 20%;

}
}

@media (max-width:567px){
    .demo-md{
        left:15px
    }
}
