

.footer {
    padding-top: 74px;
    padding-bottom: 54px;
    background: #212121;
    background: #02060D!important;
}
.footer li:nth-of-type(n+2) {
    margin-left: 8px;
}
.footer a {
    font-size: 18px;
    color: rgba(255, 255, 255, .5);
    transition: color 0.235s ease-in-out;
    font-family: 'Br Firma', sans-serif;
}
.footer a:focus, .footer a:hover {
    color: rgba(255, 255, 255, .25);
}

.footer .container .row p {
    color: white;
    font-weight: 400;
    font-size: 17px;
    font-family: 'Br Firma';
}
.footer .container .row h5, h6 {
    color: #BEFFCB;

}
.footer .social_wrapper{
    
}
.footer .container .row h3{
    color: #BEFFCB;
    margin-bottom: 27px;
    font-family: 'BR Firma', sans-serif;
                                                
}
.footer .fab{
    color: #BEFFCB;
    font-size: 30px;
}
.humber_email{
    color:white!important;
    font-size:26px;
}
hr{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 2px solid #ffffff;
}

.box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 119px;
}


@media(max-width:767px){
    .footer-md-hidden{
        display: none;
    }
    .md-margin{
        
        margin-bottom: 2px!important;
        margin-top: 16px;
    }
    .footer .social_wrapper {
        width: 10px;
    }


}

@media(max-width:480px){
     .logo-md svg{
      
    height: 30px;
    margin-left: -54px;
    margin-bottom: -40px;
     }
     .humber_email{
        font-size: 11px!important;
     }

}
@media(max-width:351px){
    .footer-top-row {
        display: grid!important;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
    width: auto;
    }
}



